import React from 'react'
import { TipScoreCard } from './components/TipScoreCard'
import { Heading } from '../Heading/Heading'
import {
    AflTippingGraphic,
    CricketTippingGraphic,
    StyledCard,
    StyledHeading,
    StyledCompCardPill,
    StyledSeasonDate,
    StyledGameTypeHeading,
} from './CompetitionScoreCard.styled'
import { ScoreDTO, SportName } from '@the-game/api-interfaces'
import { Box, GameThemeProvider } from '@the-game/components'
import { Football, AflWFootball, CricketBall, WaflFootball } from '../Icons'
import cricketBatRender from './assets/TG-crk-SM-1000w.png'
import footballRender from './assets/TG-afl-SM-1000w.png'
import waflFootballRender from './assets/TG-wafl-SM-1000w.png'
import { useGameContext } from '@the-game/web-common'

export function CompetitionScoreCard({
    tippingScore,
    newGame,
    linkButton,
    isComingSoon,
}: {
    tippingScore: ScoreDTO | undefined
    newGame?: Boolean
    linkButton: React.ReactNode
    isComingSoon?: Boolean
}) {
    const { sport, season } = useGameContext()
    const isFootball = sport === 'AFL' || sport === 'WAFL' || sport === 'AFLW'
    const SportIcon: Record<SportName, JSX.Element> = {
        AFL: (
            <Football aria-hidden title="" size="md" rotate={45} gradientFill />
        ),
        AFLW: (
            <AflWFootball aria-hidden title="" size="md" themeColor={'aflW'} />
        ),
        Cricket: <CricketBall aria-hidden title="" size="md" gradientFill />,
        WAFL: (
            <WaflFootball
                aria-hidden
                title=""
                size="md"
                themeColor="waflTeal"
            />
        ),
    }

    return (
        <GameThemeProvider sport={sport}>
            <Box position="relative">
                {newGame && (
                    <StyledCompCardPill variant="warning">
                        New!
                    </StyledCompCardPill>
                )}
                <StyledCard newGame={newGame} isComingSoon={isComingSoon}>
                    <StyledHeading>
                        {SportIcon[sport]}
                        <Heading level="3" size="6">
                            {sport}
                        </Heading>
                        <div>
                            <StyledGameTypeHeading level="3" size="4">
                                Tipping
                            </StyledGameTypeHeading>
                            <StyledSeasonDate>{season}</StyledSeasonDate>
                        </div>
                    </StyledHeading>
                    {isFootball ? (
                        <AflTippingGraphic
                            src={
                                sport === 'AFL'
                                    ? footballRender
                                    : waflFootballRender
                            }
                            newGame={newGame}
                            isComingSoon={isComingSoon}
                            aria-hidden
                        />
                    ) : (
                        <CricketTippingGraphic
                            src={cricketBatRender}
                            newGame={newGame}
                            isComingSoon={isComingSoon}
                            aria-hidden
                        />
                    )}
                    {linkButton}
                    {!newGame && !isComingSoon && (
                        <TipScoreCard scoreData={tippingScore} sport={sport} />
                    )}
                </StyledCard>
            </Box>
        </GameThemeProvider>
    )
}
